import React from 'react';
import styles from '../SplashLanding.module.css'; // Import the CSS file

function SplashLanding() {
    return (
        <section id='splash' className={styles.splash}>
            <div className={styles.videoContainer}>
                <iframe src="https://player.vimeo.com/video/1011407802?badge=0&amp;controls=0&amp;badge=0&amp;title=0&amp;byline=0&amp;portrait=0&amp;muted=1&amp;autopause=0&amp;loop=1&amp;autoplay=1&amp;player_id=0&amp;app_id=58479" className={styles.iframeVideo} frameBorder="0">
                </iframe>

                <span className={styles.spanOverlayText}>
                    Business offices are rejoicing ...
                </span>

            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </section>
    );
};

export default SplashLanding;